import 'babel-polyfill';
import { detect } from 'detect-browser';
import React from 'react';
import { Provider } from 'react-redux';
import App, { Container } from 'next/app';
import withRedux from 'next-redux-wrapper';
import axios from 'axios';
import { setCookie, getCookie } from '../utils/CookieUtils';
import Pa2OutdatedBrowser from '../components/Pa2OutdatedBrowser';
import { initStore } from '../store';

const browser = detect();

export default withRedux(initStore, { debug: false })(class MyApp extends App {

    static async getInitialProps({ Component, ctx, req }) {
      // console.log('cookie app', getCookie('sessionId'));
      //const resUser = await axios.get(`http://pac.publitalia.srl/api/users/sessionid/${getCookie('sessionId')}`);
      let jsonUser = '';
      if (req && jsonUser) {
        jsonUser = 'resUser.data';
      }
      return {
            pageProps: {
                // Call page-level getInitialProps
                ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
            },
            //userData: jsonUser
      };
    }

    // componentDidMount() {
    //  this.getDati();
    // }

    // async getDati() {
    //   const resUser = await axios.get(`http://pac.publitalia.srl/api/users/sessionid/${getCookie('sessionId')}`);
    //   const jsonUser = resUser.data;
    //   //console.log('jsonUser', jsonUser);
    //   this.setState({
    //     userData: jsonUser,
    //   });
    // }

    render() {
        const { Component, pageProps, store } = this.props;
        console.log('browser', browser, browser.name);
        if (browser.name === 'ie') {
          return (
            <Container>
              <Pa2OutdatedBrowser />
            </Container>
          );
        }
        return (
            <Container>
                <Provider store={store}>
                    <Component {...pageProps} />
                </Provider>
            </Container>
        );
    }

});
