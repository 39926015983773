import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

const initialState = {
  tk: 'token demo 123',
  cart: [],
  errmessage: '',
  pointsLoaded: false,
  qtaLoaded: false,
  carrelloProdPattui: [],
  carrelloValComm: '',
  totalePuntiPattui: 0
  //thisProd: ''
};

// reducer base
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'errGetCart':
            return { ...state, errmessage: 'errore carrello' };
        case 'addTK':
            return { ...state, tk: action.payload };
        case 'pointsLoaded':
            return { ...state, pointsLoaded: action.payload };
        case 'qtaLoaded':
            return { ...state, qtaLoaded: action.payload };
        // case 'thisProd':
        //     return { ...state, thisProd: action.payload };
        case 'getCarrello':
            return { ...state, cart: [action.payload],
            };
        case 'carrelloTotali':
            return {
              ...state,
              puntiTot: action.payload.punti,
              valoreTot: action.payload.valore,
              ivaTot: action.payload.iva,
            };
        case 'addQta':
            return { ...state, [action.payload]: action.idQta };
        case 'carrelloProdPattui':
            return { ...state, carrelloProdPattui: action.payload };
        case 'carrelloValComm':
            return { ...state, carrelloValComm: action.payload };
        case 'totalePuntiPattui':
            return { ...state, totalePuntiPattui: action.payload };
        default:
            return state;
    }
};

// create a store creator
export const initStore = (store = initialState) => {
    return createStore(reducer, store, composeWithDevTools(applyMiddleware(thunkMiddleware)));
};
