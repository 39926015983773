// export const midPiat = '5a4c1f0c80a856367336829a';
// export const pkPiat = 1;
// export const serverUrl = 'https://pac.promoattiva.com';
exports.data = {
  idPiat: "5d6792bd05760619682c0805",
  pkPiat: 15,
  serverUrl: "https://pac.promoattiva.com",
  // serverUrl: "http://localhost:3001",
  APIcode: "uK6D7kzrtbFR23U6MSFq4aWc",
  ragioneSocialePiattaforma: "Fischer Italia S.r.l.",
  giorniDiConsegna: 20,
  arrayMetaPiuRichiesti: [
    '1680-00047',
    '1037-MZB6975EU',
    '1432-6250U',
    '1432-5650U',
    '1680-06AM',
    '1037-MWM02QL/A',
    '1680-02LP',
    '329-AT701',
    '1916-G20034',
    '40-SMG973FZGDITV',
    '40-UE32N4000',
    '1037-H50B7120',
    '1037-MWLU2QL/A',
    '1037-MWLT2QL/A',
    '1037-PS4',
    '1037-S9711/32',
    '40-UE32N4000',
    '1243-BOT227',
    '1432-E200',
    '395-MTRSPX150',
    '535-029030-35M',
    '535-029030-95M',
    '535-029033-35M',
    '535-029033-95M',
    '535-028230-35M',
    '535-028230-95M',
    '535-028219-35M',
    '535-028235-35M',
    '535-028235-95M',
    '535-021034-35M',
    '535-021034-95M',
    '535-021033-35M',
    '535-021033-95M',
    '535-020911-35M',
    '535-020911-96M',
    '535-020910-35M',
    '535-020910-96M',
    '535-040161-35M',
    '1139-992371', // old '1139-992001-M',
    '1139-992511-M',
    '326-K18070NGR',
    '329-10067121', // old '329-10029502',
    '329-38674250',
    '329-38674890',
    '1860-E22E',
    '1860-SPILLO',
    '1432-E200',
    '937-BRUSH-PLUS-SL',
    '937-BRUSH-PLUS-SLS',
    '937-B-TOUR-SLS',
    '937-B-TOUR-XLS',
    '1374-95123',
    '1037-MZB8699EU',
    'CARD-Q8',
    'E-TICKET',
    '40-UE55TU8500UXZT',
    '40-HG24EE690',
    '137-NXMB20V1',
    '1037-LC43B',
    '1139-994552',
    '1139-994602',
    '1139-994532',
    '1139-994672',
    '1139-994622',
    '1139-994631',

    '1139-993045',
    '1139-993049',
    '1139-994652',
    '1139-991851',
    '1139-991444',
    '1139-988964',
    '1139-991314',
    '1139-991318',
    '1139-988774',
    '1139-989241',
    '1139-994025',
    '1139-994022',
    '1139-992354',
    '1139-992351',
    '1139-991694',
    '1139-991691-c',
    '1730-5622-001',
    '1037-778197',
    '1037-MWP22TY',
    'E-TICKET-CARTA',
    '1037-TS6816',
    '1037-SMT32N30HV1',
    '1037-2800195',
    '40-SMR890',
    '1037-M06T3TY/A',
    '1037-DYV15',
    '1037-MLLK3QL/A',
    '1139-987533',
    '1139-994211',
    '1139-994213',
    '1984-NY0085-18E',
    '1037-C11CJ20401',
    '1037-C11CJ21401',
    '1803-SON17',
    '1803-SONFIVE',
    '1803-SON115',
    '1803-SON202',
    '986-M-VR222',
    '1432-106600U',
    '1037-MHW83TY/A',
    '1037-MHR73TY/A',
    '40-SMA035GZK',
    '1037-ET14000',
    '1037-MV7N2TY/A',
    '1037-MZB0DL5EU',
    '40-SMA346BZKAEUE',
    '1730-3764-008'
  ]
};

exports.mongo = {
  mongouser: "pac2mongo",
  mongopass: "34q5WAg8h7",
  mongoip: "5.249.153.152",
  mongoport: "27017",
  mongodbname: "pac"
};

// exports.mailSmtp = {
//   host: "smtp.sparkpostmail.com",
//   port: 587,
//   // secure: true,
//   auth: {
//     user: "SMTP_Injection",
//     pass: "c854182ac9531c02c2c799601209040760ed2109"
//   }
// };

exports.mailSmtp = {
  host: 'smtp-relay.sendinblue.com',
  port: 465,
  secure: true,
  auth: {
    user: 'web@publitaliasrl.com',
    pass: 'X6V5gjHfWAnqCya4'
  }
};

exports.datiMailAssist = {
  from: '"noreply fischergift" <comunicazioni@promoattiva.com>', // sender address
  cc: ['sviluppo@publitaliasrl.com', 'supporto@publitaliasrl.com'], // list of receivers
  //bcc: ['supporto@espansione8.com'], // list of receivers
  subject: 'Assistenza FischerGift pattuizione' // Subject line
};

exports.datiMailRichiesta = {
  from: '"noreply fischergift" <comunicazioni@promoattiva.com>', // sender address
  to: ['pattuizioni@fischeritalia.it'],
  // cc: ['sviluppo@publitaliasrl.com'], // list of receivers
  bcc: ['supporto@publitaliasrl.com'], // list of receivers
  subject: 'Richiesta password FischerGift' // Subject line
};

exports.datiMailCliente = {
  from: '"noreply fischergift" <comunicazioni@promoattiva.com>',
  cc: ['sviluppo@publitaliasrl.com', 'supporto@publitaliasrl.com'],
  // bcc: [''],
  piattaforma: 'FischerGift pattuizione',
  footer: 'FischerGift'
};

exports.datiMailadmin = {
  from: '"noreply fischergift" <comunicazioni@promoattiva.com>',
  to: ['sviluppo@publitaliasrl.com'],
  cc: ['pattuizioni@fischeritalia.it'],
  // cc: ['barbara.fante@fischeritalia.it', 'monica.paccagnella@fischeritalia.it'],
  bcc: ['supporto@publitaliasrl.com'],
  piattaforma: 'FischerGift pattuizione',
  footer: 'FischerGift'
};

exports.province = [
  'AG',
  'AL',
  'AN',
  'AO',
  'AR',
  'AP',
  'AT',
  'AV',
  'BA',
  'BT',
  'BL',
  'BN',
  'BG',
  'BI',
  'BO',
  'BZ',
  'BS',
  'BR',
  'CA',
  'CL',
  'CB',
  'CI',
  'CE',
  'CT',
  'CZ',
  'CH',
  'CO',
  'CS',
  'CR',
  'KR',
  'CN',
  'EN',
  'FM',
  'FE',
  'FI',
  'FG',
  'FC',
  'FR',
  'GE',
  'GO',
  'GR',
  'IM',
  'IS',
  'SP',
  'AQ',
  'LT',
  'LE',
  'LC',
  'LI',
  'LO',
  'LU',
  'MC',
  'MN',
  'MS',
  'MT',
  'ME',
  'MI',
  'MO',
  'MB',
  'NA',
  'NO',
  'NU',
  'OT',
  'OR',
  'PD',
  'PA',
  'PR',
  'PV',
  'PG',
  'PU',
  'PE',
  'PC',
  'PI',
  'PT',
  'PN',
  'PZ',
  'PO',
  'RG',
  'RA',
  'RC',
  'RE',
  'RI',
  'RN',
  'RM',
  'RO',
  'SA',
  'VS',
  'SS',
  'SV',
  'SI',
  'SR',
  'SO',
  'TA',
  'TE',
  'TR',
  'TO',
  'OG',
  'TP',
  'TN',
  'TV',
  'TS',
  'UD',
  'VA',
  'VE',
  'VB',
  'VC',
  'VR',
  'VV',
  'VI',
  'VT'
];
