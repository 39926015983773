import React from 'react';
import Router from 'next/router';
import Link from 'next/link';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
  Container
} from 'reactstrap';
import { setCookie, getCookie } from '../utils/CookieUtils';
import PageLoader from './PageLoader';
// import { idPiat } from '../config';
import { data } from '../config';
import {
  getCarrello,
  carrelloProdPattui,
  carrelloValComm,
  totalePuntiPattui
} from '../actions';

/*global sessionStorage window */

class PA2Header extends React.Component {
  state = {
    isOpen: false,
    //thisCart: this.props.users.carrello,
    pageLoading: true,
    scrollingLock: false
  };

  componentWillMount() {
    //this.getDati();
    //console.log('Header state', this.state);
    this.props.getCartProp(this.props.users._id);
    if (
      sessionStorage.getItem('sessionStorageProdPattui') !== null &&
      sessionStorage.getItem('sessionStorageValComm') === null
    ) {
      this.props.carrelloProdPattui(
        ...[
          JSON.parse(
            window.atob(sessionStorage.getItem('sessionStorageProdPattui'))
          )
        ]
      );
    } else if (
      sessionStorage.getItem('sessionStorageValComm') !== null &&
      sessionStorage.getItem('sessionStorageProdPattui') === null
    ) {
      this.props.carrelloValComm(
        ...[
          JSON.parse(
            window.atob(sessionStorage.getItem('sessionStorageValComm'))
          )
        ]
      );
    } else if (
      sessionStorage.getItem('sessionStorageProdPattui') !== null &&
      sessionStorage.getItem('sessionStorageValComm') !== null
    ) {
      const arrayVuoto = [];
      this.props.carrelloProdPattui(arrayVuoto);
      this.props.carrelloValComm('');
      sessionStorage.clear();
    }
  }

  componentDidMount() {
    //this.getDati();
    this.salvaPuntiPattui(this.saldoPuntiPattui());
    this.setState({
      dataUser: this.props.users,
      thisCart: this.props.users.carrello,
      pageLoading: false
    });
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    // console.log('window.scrollY',window.scrollY);
    if (window.scrollY > 150) {
      // console.log('should lock');
      this.setState({ scrollingLock: true });
    } else if (window.scrollY < 150) {
      // console.log('not locked');
      this.setState({ scrollingLock: false });
    }
  };

  componentDidUpdate(v) {
    if (this.props.redux.totalePuntiPattui !== this.saldoPuntiPattui()) {
      this.salvaPuntiPattui(this.saldoPuntiPattui());
    }
    // console.log('componentDidUpdate');
    // console.log('Header update', this.state);
    // console.log('this.state.tok', this.state.tok);
    // console.log('this.state.piat', this.state.piat);
  }

  // async getDati() {
  // 	this.setState({ iconLoading: true });
  // 	const resUser = await axios.get(`${data.serverUrl}/api/users/${getCookie('x-access-token')}`);
  //   const jsonUser = resUser.data;
  // 	this.setState({
  // 		iconLoading: false,
  // 		dataUser: jsonUser,
  // 	});
  // }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  logout = () => {
    setCookie('x-access-token', '');
    setCookie('sessionId', '');
    Router.push({
      pathname: '/login'
    });
  };
  salvaPuntiPattui(val) {
    this.props.totalePuntiPattui(val);
    console.log('salvaPuntiPattui');
  }
  saldoPuntiPattui() {
    /* INIZIO Somma Punti Pattuizione */
    const puntiProdPattuiData = [];
    if (this.props.redux.carrelloProdPattui) {
      for (let i = 0; i < this.props.redux.carrelloProdPattui.length; ++i) {
        const puntiSingoloProdPattui = `puntiSingoloProdPattui${
          this.props.redux.carrelloProdPattui[i].idProdPatt
        }`;
        if (this.props.redux.carrelloProdPattui[i] !== null) {
          puntiProdPattuiData.push(
            this.props.redux.carrelloProdPattui[i].puntiSingoloProdPattui
          );
        }
      }
    }
    /* FINE Somma Punti Pattuizione */
    /* INIZIO Punti valore commerciale */
    let puntiValCommPattuiData;
    if (this.props.redux.carrelloValComm) {
      puntiValCommPattuiData = this.props.redux.carrelloValComm.puntiPattuiValore;
    }
    /* FINE Punti valore commerciale */
    const puntiTotPattui =
      puntiProdPattuiData.reduce((a, b) => a + b, 0) || puntiValCommPattuiData || 0;
    return Number(puntiTotPattui);
  }

  // consoleCart = async () => {
  //   const newCart = await axios.get(`${data.serverUrl}/api/users/cart/${this.props.users._id}`);
  //   const carcar = newCart.data[0].carrello;
  // console.log('carcar', carcar);
  // }

  render() {
    if (!this.state.dataUser) {
      // console.log('this state header NOT', this.state);
      // console.log('this props header NOT', this.props);
      return <PageLoader />;
    }

    const cartData = [];
    const cartArray = this.props.cart[0];
    const thisCart = this.props.users.carrello;
    if (!cartArray) {
      for (let i = 0; i < thisCart.length; ++i) {
        if (thisCart[i] !== null) {
          cartData.push(thisCart[i].qta);
        } else {
          const rimuoviItemNull = axios.delete(
            `${data.serverUrl}/api/users/cartisnull/${this.props.users._id}`
          );
        }
      }
    } else {
      for (let i = 0; i < cartArray.length; ++i) {
        if (cartArray[i] !== null) {
          cartData.push(cartArray[i].qta);
        } else {
          const rimuoviItemNull = axios.delete(
            `${data.serverUrl}/api/users/cartisnull/${this.props.users._id}`
          );
        }
      }
    }
    const cartQta = cartData.reduce((a, b) => a + b, 0);

    let profiloAgente;
    if (this.state.dataUser.level === 'senior') {
      profiloAgente = (
        <NavItem>
          <Link prefetch href="/profilo-agente">
            <NavLink
              style={{ cursor: 'pointer' }}
              className="menuScrittaWh"
              href=""
            >
              <i className="fas fa-user" /> Profilo Agente
            </NavLink>
          </Link>
        </NavItem>
      );
    }

    return (
      <div>
        <Col
          lg="12"
          className={this.state.scrollingLock ? '' : 'menuFixedMobile'}
          style={{ visibility: 'hidden' }}
        >
          <div
            className="menuFixedMobilePlaceholder"
            style={{ height: '120px' }}
          />
        </Col>
        <Navbar
          color="faded"
          light
          expand="xl"
          style={{
            paddingRight: '0px',
            paddingLeft: '0px',
            paddingBottom: '0px'
          }}
          className={this.state.scrollingLock ? 'menuFixedMobile' : ''}
        >
          <NavbarBrand className="logoEsterno" href="/">
            <img
              src="../static/img/fischer_logo_M_pos_4c.png"
              alt="Logo"
              style={{ width: '250px' }}
            />
            <br />
            <img
              src="../static/img/pattuizioni-commerciali.png"
              alt="Logo2"
              style={{ width: '250px' }}
            />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Container
              fluid
              style={{ paddingRight: '0px', paddingLeft: '0px' }}
            >
              <Row noGutters>
                <Col lg="12">
                  <Nav className="float-left px-3" navbar>
                    <NavbarBrand className="logoCollapse" href="/">
                      <img
                        src="../static/img/fischer_logo_M_pos_4c.png"
                        alt="Logo"
                        style={{ width: '250px' }}
                      />
                      <img
                        src="../static/img/pattuizioni-commerciali.png"
                        alt="Logo2"
                        style={{ width: '250px' }}
                      />
                    </NavbarBrand>
                    <NavItem>
                      <NavLink>
                        Buongiorno {this.state.dataUser.ragsoc} {`${this.state.dataUser.nome} ${this.state.dataUser.cognome}`} {' '}
                      </NavLink>
                    </NavItem>
                    {this.state.dataUser.level !== 'user' ? (
                      <NavItem>
                        <NavLink>
                          <b>
                            <i className="fas fa-child" /> Saldo punti:
                          </b>{' '}
                          {this.saldoPuntiPattui()}
                        </NavLink>
                      </NavItem>
                    ) : null}
                    {/* <NavItem>
                      <NavLink>
                        <b>
                          <i className="fas fa-copy" /> Fatturato:
                        </b>{' '}
                        {this.state.dataUser.fatturato}
                      </NavLink>
                    </NavItem> */}
                    {/* <NavItem>
                      <NavLink>
                        <b>
                          <i className="fas fa-bullseye" /> Target:
                        </b>{' '}
                        {this.state.dataUser.target0
                          ? this.state.dataUser.target0
                          : 0}
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </Col>
                <Col
                  lg="12"
                  className={this.state.scrollingLock ? '' : 'menuFixed'}
                  style={{ visibility: 'hidden' }}
                >
                  <hr
                    className="menuFixedPlaceholder"
                    style={{ height: '100%', maxHeight: '46px' }}
                  />
                </Col>
                <Col
                  lg="12"
                  className={this.state.scrollingLock ? 'menuFixed' : ''}
                >
                  <Nav className="ml-auto bordoSxMenu menuSfondoBl" navbar>
                    {this.state.dataUser.level !== 'user' ? (
                      <NavItem>
                        <Link prefetch href="/">
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className="menuScrittaWh"
                            href=""
                          >
                            <i className="fas fa-home" /> Pattuizioni Prodotti
                          </NavLink>
                        </Link>
                      </NavItem>
                    ) : null}
                    {this.state.dataUser.level === 'supervisor' || this.state.dataUser.level === 'admin' ? (
                      <NavItem>
                        <Link prefetch href="/pattuizione-valore">
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className="menuScrittaWh"
                            href=""
                          >
                            <i className="fas fa-home" /> Pattuizione Valore
                          </NavLink>
                        </Link>
                      </NavItem>
                    ) : null}
                    <NavItem>
                      <Link  href="/catalogo">
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          className="menuScrittaWh"
                          href=""
                        >
                          <i className="fas fa-shopping-bag" /> Catalogo
                        </NavLink>
                      </Link>
                    </NavItem>
                    {this.state.dataUser.level !== 'user' ? (
                      <React.Fragment>
                        {/* <NavItem>
                          <Link prefetch href="/profilo">
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className="menuScrittaWh"
                              href=""
                            >
                              <i className="fas fa-user" /> Profilo
                            </NavLink>
                          </Link>
                        </NavItem> */}
                        <NavItem>
                          <Link prefetch href="/storico">
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className="menuScrittaWh"
                              href=""
                            >
                              <i className="fas fa-file-alt" /> Pattuizioni
                            </NavLink>
                          </Link>
                        </NavItem>
                        {/* <NavItem>
                          <Link prefetch href="/regolamento">
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className="menuScrittaWh"
                              href=""
                            >
                              <i className="fas fa-info-circle" /> Regolamento
                            </NavLink>
                          </Link>
                        </NavItem> */}
                        <NavItem>
                          <Link href="/carrello">
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className="menuScrittaWh"
                              href=""
                            >
                              <i className="fas fa-shopping-cart" /> Carrello (
                              {cartQta})
                              {/* (oggetti: {this.props.cart[0] ? this.props.cart[0].length : 0}) */}
                            </NavLink>
                          </Link>
                        </NavItem>
                      </React.Fragment>
                    ) : null}
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className="menuScrittaWh"
                        href=""
                        onClick={this.logout}
                      >
                        <i className="fas fa-sign-out-alt" /> Logout
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {/* <Nav className="menuSfondoBl" style={{float:"right"}}> */}
                  {/* <NavItem>
                    <NavLink className="menuScrittaWh buttonLogout" href="" onClick={this.logout}><i className='fas fa-sign-out-alt' /> Logout</NavLink>
                </NavItem> */}
                  {/* </Nav> */}
                </Col>
              </Row>
            </Container>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
//console.log('this.props.cart[0].data.cart', this.props.cart[0].data.cart[0].carrello);
const mapStateToProps = state => ({
  cart: state.cart,
  errmessage: state.errmessage,
  redux: state
});

const mapDispatchToProps = dispatch => ({
  getCartProp: bindActionCreators(getCarrello, dispatch),
  carrelloProdPattui: bindActionCreators(carrelloProdPattui, dispatch),
  carrelloValComm: bindActionCreators(carrelloValComm, dispatch),
  totalePuntiPattui: bindActionCreators(totalePuntiPattui, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PA2Header);
