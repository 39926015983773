import React from 'react';
import axios from 'axios';
import Router from 'next/router';
//import withRedux from 'next-redux-wrapper';
import { connect } from 'react-redux';
//import { bindActionCreators } from 'redux';
import HeadHtml from '../components/HeadHtml';
import PageLoader from '../components/PageLoader';
import PA2Header from '../components/PA2Header';
import PA2Home from '../components/PA2Home';
import PA2Footer from '../components/PA2Footer';
import { getCookie } from '../utils/CookieUtils';
//import { initStore } from '../store';
import { data } from '../config';

class Index extends React.Component {
  // static getInitialProps({ store }) {
  //   store.dispatch({ type: 'getTk', payload: '456' });
  // }
  state = {
    pageLoading: true,
  };

  componentDidMount() {
    this.getDati();
    this.setState({ pageLoading: false });
    console.log('initial props', this.props);
    //setTimeout(() => this.setState({ pageLoading: false }), 5000); // caricamento simulato
  }

    componentDidUpdate() {
      //this.setState({ pageLoading: true });
      console.log('didUp props', this.props);
    }

  async getDati() {
    const resUser = await axios.get(`${data.serverUrl}/api/users/sessionid/${getCookie('sessionId')}`);
    const jsonUser = resUser.data;
    // console.log('jsonUser', jsonUser);
    this.setState({
      userData: jsonUser,
    });
  }

	render() {
    if (!this.state.userData) {
      return <PageLoader />;
    }
    if (this.state.userData.level === 'user') {
      Router.push('/catalogo');
      return <PageLoader />;
    }
    console.log('render state', this.state);
    console.log('render props', this.props);
		return (
      <div>
        <HeadHtml>
          <PA2Header users={this.state.userData} />
          <PA2Home users={this.state.userData} />
          <PA2Footer />
        </HeadHtml>
      </div>
		);
	}
}
//export default withRedux(initStore, (state) => ({ token: state.tk, stateRedux: state }))(Index);
const mapStateToProps = state => {
  return {
    tkLogin: state.tk,
    redux: state
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //addTKprop: bindActionCreators(addTK, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
