import React from 'react';

export default class PageLoader extends React.Component {

	render() {
		return (
      <div>
        <img
          src={'../static/img/index.rotate-pie-preloader-gif.svg'}
          width="300"
          height="300"
          alt="spinner"
          style={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-150px', marginLeft: '-150px' }}
        />
      </div>
		);
	}
}
