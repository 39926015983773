import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import Link from 'next/link';
import { carrelloProdPattui, carrelloValComm } from '../actions';
import { data } from '../config';

/*global sessionStorage window*/

const descrProdPattui = {
  520573: 'FSB BOX N. 20 CARTUCCE FIS SB 390 S + N. 40 MISCELATORI',
  // 521730: 'FIS BOX C/16 CART. V 410 C', //558781
  558781: 'FIS V PLUS BOX N. 16 CARTUCCE FIS V PLUS + N. 32 MISCELATORI',
  544160: 'EPOXY BOX N. 20 CARTUCCE FIS EM 390 PLUS + N. 20 MISCELATORI',
  519450: 'FIS SB 390 S',
  // 521431: 'FIS V 410 C', //558780
  558780: 'FIS V PLUS 410 C',
  544163: 'FIS EM PLUS 390S'
};

class PA2Home extends React.Component {
  state = {
    pattuizioniProd: [],
    pezzi520573: 0, // immagine
    prezzo520573: 0,
    // pezzi521730: 0, // pezzi558781
    // prezzo521730: 0, // prezzo558781
    pezzi558781: 0, 
    prezzo558781: 0, 
    pezzi544160: 0, // immagine
    prezzo544160: 0,
    // pezzi521431: 0, // pezzi558780
    // prezzo521431: 0, // prezzo558780
    pezzi558780: 0,
    prezzo558780: 0,
    pezzi519450: 0, // immagine
    prezzo519450: 0,
    pezzi544163: 0, // FIS EM PLUS 390 S - immagine
    prezzo544163: 0,
  };
  async componentDidMount() {
    console.log('Redux', this.props.redux);
    // await sessionStorage.clear();
    await sessionStorage.removeItem('sessionStorageValComm');
    await this.props.carrelloValComm('');
    if (sessionStorage.getItem('sessionStorageProdPattui') !== null) {
      await this.props.carrelloProdPattui(
        ...[
          JSON.parse(
            window.atob(sessionStorage.getItem('sessionStorageProdPattui'))
          )
        ]
      );
    }
  }
  onChangeInputSetState = e => {
    this.setState({ [e.target.name]: e.target.valueAsNumber || 0 });
    // console.log('onChangeInputSetState', e.target.name, e.target.value, e.target);
  };
  calcoloPunti = (pezziValue, prezzoValue) => {
    const calcoloP = (pezziValue * prezzoValue) / 100;
    return Number(calcoloP.toFixed(0));
  };
  calcoloCosto = (pezziValue, prezzoValue) => {
    const calcoloC = pezziValue * prezzoValue;
    return Number(calcoloC.toFixed(2));
  };
  calcoloTotaleCostoPattui() {
    const totaleCostoPattui = [];
    if (this.props.redux.carrelloProdPattui) {
      for (let i = 0; i < this.props.redux.carrelloProdPattui.length; ++i) {
        const totaleCosto = `totaleCosto${
          this.props.redux.carrelloProdPattui[i].idProdPatt
        }`;
        if (this.props.redux.carrelloProdPattui[i] !== null) {
          totaleCostoPattui.push(
            this.props.redux.carrelloProdPattui[i].costoTotSingoloProdPattui
          );
        }
      }
    }
    const sommaTotaleCosto = totaleCostoPattui.reduce((a, b) => a + b, 0) || 0;
    return Number(sommaTotaleCosto.toFixed(2));
  }
  storeTosessionStorage = () => {
    const reduxTosessionStorage = JSON.stringify(
      this.props.redux.carrelloProdPattui
    );
    sessionStorage.setItem(
      'sessionStorageProdPattui',
      window.btoa(reduxTosessionStorage)
    );
  };
  addProdPatt = async (idProdPatt, e) => {
    e.preventDefault();
    const campoPezzi = `pezzi${idProdPatt}`;
    const campoPrezzo = `prezzo${idProdPatt}`;
    // const puntiSingoloProdPattui = `puntiSingoloProdPattui${idProdPatt}`;
    // const totaleCosto = `totaleCosto${idProdPatt}`;
    const prodottoScelto = await this.props.redux.carrelloProdPattui.find(
      item => item.idProdPatt === idProdPatt
    );
    if (
      !prodottoScelto &&
      (this.state[campoPezzi] !== 0 && this.state[campoPrezzo] !== 0)
    ) {
      this.props.redux.carrelloProdPattui.push({
        idProdPatt,
        descrProdPattui: descrProdPattui[idProdPatt],
        pezziProdPattui: this.state[campoPezzi] || 0,
        prezzoProdPattui: this.state[campoPrezzo] || 0,
        puntiSingoloProdPattui: this.calcoloPunti(this.state[campoPezzi], this.state[campoPrezzo]) || 0,
        costoTotSingoloProdPattui: this.calcoloCosto(this.state[campoPezzi], this.state[campoPrezzo]) || 0
      });
    } else {
      for (let i = 0; i < this.props.redux.carrelloProdPattui.length; i++) {
        // if (this.props.redux.carrelloProdPattui[i].idProdPatt === idProdPatt && (!isNaN(this.state[campoPezzi]) && !isNaN(this.state[campoPrezzo]))) {
        if (this.props.redux.carrelloProdPattui[i].idProdPatt === idProdPatt) {
          this.props.redux.carrelloProdPattui[i] = {
            idProdPatt,
            descrProdPattui: descrProdPattui[idProdPatt],
            pezziProdPattui: this.state[campoPezzi] || 0,
            prezzoProdPattui: this.state[campoPrezzo] || 0,
            puntiSingoloProdPattui: this.calcoloPunti(
                this.state[campoPezzi],
                this.state[campoPrezzo]
              ) || 0,
            costoTotSingoloProdPattui: this.calcoloCosto(
                this.state[campoPezzi],
                this.state[campoPrezzo]
              ) || 0
          };
        }
      }
      // this.setState({
      //   pattuizioniProd: this.props.redux.carrelloProdPattui
      // });
    }
    await this.props.carrelloProdPattui(
      ...[this.props.redux.carrelloProdPattui]
    );
    await this.storeTosessionStorage();
    // this.setState(this.state);
    // this.setState({ pattuizioniProd: this.props.redux.carrelloProdPattui });
  };
  delProdPatt = async idProdPatt => {
    const prodottoDelete = this.props.redux.carrelloProdPattui.filter(
      item => item.idProdPatt !== idProdPatt
    );
    await this.props.carrelloProdPattui(...[prodottoDelete]);
    console.log('prodottoDelete', prodottoDelete);
    await this.storeTosessionStorage();
  };

  render() {
    // window.onbeforeunload = function (e) {
    //   console.log('event', e);
    //   // sessionStorage.clear();
    //   return 'Are you sure????';
    // };
    console.log('index state', this.state);
    const riepilogoDatiPattui = this.props.redux.carrelloProdPattui.map(
      prodotto => {
        const campoPezzi = `pezzi${prodotto.idProdPatt}`;
        const campoPrezzo = `prezzo${prodotto.idProdPatt}`;
        // const puntiSingoloProdPattui = `puntiSingoloProdPattui${prodotto.idProdPatt}`;
        const totaleCosto = `totaleCosto${prodotto.idProdPatt}`;
        return (
          <div key={prodotto.idProdPatt}>
            ID Prodotto: {`${prodotto.idProdPatt}`} <br />
            Descrizione: {`${prodotto.descrProdPattui}`} <br />
            Pezzi: {`${prodotto.pezziProdPattui}`} <br />
            Prezzo caduna: {`${prodotto.prezzoProdPattui}`} <br />
            Totale ordine prodotto: {`${prodotto.costoTotSingoloProdPattui}`} <br />
            Totale punti: {`${prodotto.puntiSingoloProdPattui}`} <br />
            <Button
              className="btn btn-danger"
              onClick={() => this.delProdPatt(prodotto.idProdPatt)}
            >
              Cancella
            </Button>{' '}
            <br />
            <br />
          </div>
        );
      }
    );
    return (
      <Container fluid>
        {this.props.users.level === 'supervisor' || this.props.users.level === 'admin' ? (
          <Row style={{ margin: '10px 0px 10px 0px' }}>
            <Col md={{ size: '4', offset: '2' }}>
              <Link href="/#pattuiProd">
                <Card
                  style={{
                    // height: '100%',
                    backgroundColor: '#000',
                    cursor: 'pointer'
                  }}
                >
                  <CardBody
                    style={{
                      // height: '300px',
                      margin: 'auto',
                      display: 'table'
                    }}
                  >
                    <h2
                      style={{
                        color: 'white',
                        display: 'table-cell',
                        verticalAlign: 'middle',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Vai alla pagina <br />
                      <br />
                      Pattuizioni Prodotti
                    </h2>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col md={{ size: '4' }}>
              <Link href="/pattuizione-valore">
                <Card
                  style={{
                    // height: '100%',
                    backgroundColor: '#dc3545',
                    cursor: 'pointer'
                  }}
                >
                  <CardBody
                    style={{
                      // height: '300px',
                      margin: 'auto',
                      display: 'table'
                    }}
                  >
                    <h2
                      style={{
                        color: 'white',
                        display: 'table-cell',
                        verticalAlign: 'middle',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Vai alla pagina <br />
                      <br />
                      Pattuizione Valore
                    </h2>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          </Row>
        ) : (
          ''
        )}

        <Row name="pattuiProd" id="pattuiProd" style={{ margin: '10px 10px 10px 10px' }}>
          <Col md={{ size: '12' }}>
            <h1>Pattuizioni Prodotti</h1>
          </Col>
        </Row>
        <Row style={{ margin: '10px 0px 10px 0px' }}>
          <Col lg={{ size: '9' }} style={{ margin: '10px 0px 10px 0px' }}>
            <Row>
              <Col lg={{ size: '4' }}>
                <Card style={{ height: '100%' }}>
                  <CardBody>
                    <CardImg
                      className="img-fluid"
                      style={{
                        maxWidth: '250px',
                        display: 'block',
                        margin: '0 auto'
                      }}
                      src="./static/img/1-520573_Fis_SB_Box.jpg"
                      alt="immagine"
                    />
                    <CardTitle>FSB BOX N. 20 CARTUCCE FIS SB 390 S + N. 40 MISCELATORI</CardTitle>
                    <CardSubtitle>520573</CardSubtitle>
                  </CardBody>
                  <CardBody>
                    <Form onSubmit={e => this.addProdPatt(520573, e)}>
                      <FormGroup>
                        <Label for="pezzi520573">N. CZ</Label>
                        <Col>
                          <Input
                            type="number"
                            name="pezzi520573"
                            min="1"
                            step="1"
                            id="pezzi520573"
                            placeholder="N. CZ"
                            required
                            onChange={e => this.onChangeInputSetState(e)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Label for="prezzo520573">Prezzo caduna</Label>
                        <Col>
                          <Input
                            type="number"
                            name="prezzo520573"
                            min="333.14"
                            // min="260"
                            step="0.01"
                            id="prezzo520573"
                            placeholder="0,00"
                            required
                            onChange={e => this.onChangeInputSetState(e)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Container fluid>
                          <Row>
                            <Col>
                              punti:{' '}
                              {this.calcoloPunti(
                                this.state.pezzi520573,
                                this.state.prezzo520573
                              )}
                            </Col>
                            <Col>
                              <Button color="primary" className="float-right">
                                Aggiungi
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={{ size: '4' }}>
                <Card style={{ height: '100%' }}>
                  <CardBody>
                    <CardImg
                      className="img-fluid"
                      style={{
                        maxWidth: '250px',
                        display: 'block',
                        margin: '0 auto'
                      }}
                      src="./static/img/2-558781-FIS-V-PLUS-410C.jpg"
                      alt="immagine"
                    />
                    <CardTitle>FIS V PLUS BOX N. 16 CARTUCCE FIS V PLUS + N. 32 MISCELATORI</CardTitle>
                    <CardSubtitle>558781</CardSubtitle>
                  </CardBody>
                  <CardBody>
                    <Form onSubmit={e => this.addProdPatt(558781, e)}>
                      <FormGroup>
                        <Label for="pezzi">N. CZ</Label>
                        <Col>
                          <Input
                            type="number"
                            name="pezzi558781"
                            min="2"
                            step="1"
                            id="pezzi"
                            placeholder="N. CZ"
                            required
                            onChange={e => this.onChangeInputSetState(e)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Label for="prezzo">Prezzo caduna</Label>
                        <Col>
                          <Input
                            type="number"
                            name="prezzo558781"
                            min="144.52"
                            // min="120"
                            step="0.01"
                            id="prezzo"
                            placeholder="0,00"
                            required
                            onChange={e => this.onChangeInputSetState(e)}
                          />
                        </Col>
                      </FormGroup>
                      <Container fluid>
                        <Row>
                          <Col>
                            punti:{' '}
                            {this.calcoloPunti(
                              this.state.pezzi558781,
                              this.state.prezzo558781
                            )}
                          </Col>
                          <Col>
                            <Button color="primary" className="float-right">
                              Aggiungi
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={{ size: '4' }}>
                <Card style={{ height: '100%' }}>
                  <CardBody>
                    <CardImg
                      className="img-fluid"
                      style={{
                        maxWidth: '250px',
                        display: 'block',
                        margin: '0 auto'
                      }}
                      src="./static/img/3-544160_ALL.jpg"
                      alt="immagine"
                    />
                    <CardTitle>EPOXY BOX N. 20 CARTUCCE FIS EM 390 PLUS + N. 20 MISCELATORI</CardTitle>
                    <CardSubtitle>544160</CardSubtitle>
                  </CardBody>
                  <CardBody>
                    <Form onSubmit={e => this.addProdPatt(544160, e)}>
                      <FormGroup>
                        <Label for="pezzi544160">N. CZ</Label>
                        <Col>
                          <Input
                            type="number"
                            name="pezzi544160"
                            min="1"
                            step="1"
                            id="pezzi544160"
                            placeholder="N. CZ"
                            required
                            onChange={e => this.onChangeInputSetState(e)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Label for="prezzo544160">Prezzo caduna</Label>
                        <Col>
                          <Input
                            type="number"
                            name="prezzo544160"
                            min="414.77"
                            // min="320"
                            step="0.01"
                            id="prezzo544160"
                            placeholder="0,00"
                            required
                            onChange={e => this.onChangeInputSetState(e)}
                          />
                        </Col>
                      </FormGroup>
                      <Container fluid>
                        <Row>
                          <Col>
                            punti:{' '}
                            {this.calcoloPunti(
                              this.state.pezzi544160,
                              this.state.prezzo544160
                            )}
                          </Col>
                          <Col>
                            <Button color="primary" className="float-right">
                              Aggiungi
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={{ size: '4' }}>
                <Card style={{ height: '100%' }}>
                  <CardBody>
                    <CardImg
                      className="img-fluid"
                      style={{
                        maxWidth: '250px',
                        display: 'block',
                        margin: '0 auto'
                      }}
                      src="./static/img/4-519450-FIS-SB-390S.jpg"
                      alt="immagine"
                    />
                    <CardTitle>FIS SB 390 S</CardTitle>
                    <CardSubtitle>519450</CardSubtitle>
                  </CardBody>
                  <CardBody>
                    <Form onSubmit={e => this.addProdPatt(519450, e)}>
                      <FormGroup>
                        <Label for="pezzi">N. pezzi</Label>
                        <Col>
                          <Input
                            type="number"
                            name="pezzi519450"
                            min="48"
                            // min="24"
                            step="12"
                            // step="6"
                            id="pezzi"
                            placeholder="N. pezzi"
                            required
                            onChange={e => this.onChangeInputSetState(e)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Label for="prezzo">Prezzo caduna</Label>
                        <Col>
                          <Input
                            type="number"
                            name="prezzo519450"
                            min="16.03"
                            // min="6.70"
                            // min="12"
                            step="0.01"
                            id="prezzo"
                            placeholder="0,00"
                            required
                            onChange={e => this.onChangeInputSetState(e)}
                          />
                        </Col>
                      </FormGroup>
                      <Container fluid>
                        <Row>
                          <Col>
                            punti:{' '}
                            {this.calcoloPunti(
                              this.state.pezzi519450,
                              this.state.prezzo519450
                            )}
                          </Col>
                          <Col>
                            <Button color="primary" className="float-right">
                              Aggiungi
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={{ size: '4' }}>
                <Card style={{ height: '100%' }}>
                  <CardBody>
                    <CardImg
                      className="img-fluid"
                      style={{
                        maxWidth: '250px',
                        display: 'block',
                        margin: '0 auto'
                      }}
                      src="./static/img/5-558780-FIS-V-PLUS-410C.jpg"
                      alt="immagine"
                    />
                    <CardTitle>FIS V PLUS 410 C</CardTitle>
                    <CardSubtitle>558780</CardSubtitle>
                  </CardBody>
                  <CardBody>
                    <Form onSubmit={e => this.addProdPatt(558780, e)}>
                      <FormGroup>
                        <Label for="pezzi">N. pezzi</Label>
                        <Col>
                          <Input
                            type="number"
                            name="pezzi558780"
                            min="24"
                            // min="48"
                            step="6"
                            // step="12"
                            id="pezzi"
                            placeholder="N. pezzi"
                            required
                            onChange={e => this.onChangeInputSetState(e)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Label for="prezzo">Prezzo caduna</Label>
                        <Col>
                          <Input
                            type="number"
                            name="prezzo558780"
                            min="8.07"
                            // min="12"
                            // min="6.70"
                            step="0.01"
                            id="prezzo"
                            placeholder="0,00"
                            required
                            onChange={e => this.onChangeInputSetState(e)}
                          />
                        </Col>
                      </FormGroup>
                      <Container fluid>
                        <Row>
                          <Col>
                            punti:{' '}
                            {this.calcoloPunti(
                              this.state.pezzi558780,
                              this.state.prezzo558780
                            )}
                          </Col>
                          <Col>
                            <Button color="primary" className="float-right">
                              Aggiungi
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={{ size: '4' }}>
                <Card style={{ height: '100%' }}>
                  <CardBody>
                    <CardImg
                      className="img-fluid"
                      style={{
                        maxWidth: '250px',
                        display: 'block',
                        margin: '0 auto'
                      }}
                      src="./static/img/6-544163-FIS-EM-PLUS-390S.jpg"
                      alt="immagine"
                    />
                    <CardTitle>
                      FIS EM PLUS 390S
                    </CardTitle>
                    <CardSubtitle>544163</CardSubtitle>
                  </CardBody>
                  <CardBody>
                    <Form onSubmit={e => this.addProdPatt(544163, e)}>
                      <FormGroup>
                        <Label for="pezzi544163">N. pezzi</Label>
                        <Col>
                          <Input
                            type="number"
                            name="pezzi544163"
                            min="24"
                            step="6"
                            id="pezzi544163"
                            placeholder="N. pezzi"
                            required
                            onChange={e => this.onChangeInputSetState(e)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Label for="prezzo544163">Prezzo caduna</Label>
                        <Col>
                          <Input
                            type="number"
                            name="prezzo544163"
                            min="18.17"
                            // min="14"
                            step="0.01"
                            id="prezzo544163"
                            placeholder="0,00"
                            required
                            onChange={e => this.onChangeInputSetState(e)}
                          />
                        </Col>
                      </FormGroup>
                      <Container fluid>
                        <Row>
                          <Col>
                            punti:{' '}
                            {this.calcoloPunti(
                              this.state.pezzi544163,
                              this.state.prezzo544163
                            )}
                          </Col>
                          <Col>
                            <Button color="primary" className="float-right">
                              Aggiungi
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            lg={{ size: '3' }}
            style={{
              margin: '10px 0px 10px 0px',
              padding: '10px 10px 10px 10px',
              border: 'solid 1px black'
            }}
          >
            <h2>Riepilogo</h2>
            <hr />
            {this.props.redux.carrelloProdPattui.length > 0 ? (
              <Link href="/catalogo">
                <Button color="primary" className="float-right">
                  Vai al catalogo
                </Button>
              </Link>
            ) : (
              <Button color="secondary" className="float-right">
                Vai al catalogo
              </Button>
            )}
            {/* <Link prefetch href="/catalogo">
              <Button color="primary" className="float-right">
                Vai al catalogo
              </Button>
            </Link> */}
            <h3>Totale costo: {this.calcoloTotaleCostoPattui()}</h3>
            <br />
            <h3>
              Totale punti:{' '}
              {this.props.redux.carrelloProdPattui
                ? this.props.redux.totalePuntiPattui
                : ''}
            </h3>
            <hr />
            {riepilogoDatiPattui}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  //conta: state.click,
  redux: state
  // puntiTotPattui: state.carrelloProdPattui,
});

const mapDispatchToProps = dispatch => ({
  //clickProp: bindActionCreators(click, dispatch)
  carrelloProdPattui: bindActionCreators(carrelloProdPattui, dispatch),
  carrelloValComm: bindActionCreators(carrelloValComm, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PA2Home);
