import React from 'react';

export default class Pa2OutdatedBrowser extends React.Component {
  render() {
    return (
      <div
        style={{
          padding: 16,
          color: '#a94442',
          backgroundColor: '#f2dede',
          border: '1px solid #ebccd1',
          marginBottom: 20,
          textAlign: 'center'
        }}
        role="alert"
      >
        <img
          src="../static/img/you_shall_not_pass.jpg"
          alt="non puoi passare"
          height="300"
          width="300"
        />
        <h3>
          Rilevato browser <strong>OBSOLETO</strong>! Per ragioni di sicurezza{' '}
          <a href="http://outdatedbrowser.com/it" className="btn-link">
            aggiornate il vostro browser
          </a>{' '}
          per una navigazione sicura.
        </h3>
      </div>
    );
  }
}
