import React from 'react';
import Head from 'next/head';
import NProgress from 'nprogress';
import Router from 'next/router';
import { Container } from 'reactstrap';

Router.onRouteChangeStart = () => {
  //console.log(`Loading: ${url}`);
  NProgress.start();
};
Router.onRouteChangeComplete = () => NProgress.done();
Router.onRouteChangeError = () => {
  //console.log(`Loading: ${url} - ERROR  ${err}`);
  NProgress.done();
};

export default class HeadHtml extends React.Component {
  render() {
    return (
      <Container fluid>
        <Head>
          <title>FischerGift 2.0</title>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          {/* <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta.2/css/bootstrap.min.css"
            integrity="sha384-PsH8R72JQ3SOdhVi3uxftmaW6Vc51MKb0q5P2rRUpPvrszuE4W1povHYgTpBfshb"
            crossOrigin="anonymous"
          /> */}
          <link
            rel="stylesheet"
            href="../static/css/bootstrap.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="../static/css/wedgesloader.css"
          />
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.4.1/css/all.css"
            integrity="sha384-5sAR7xN1Nv6T6+dT2mhtzEpVJvfS3NScPQTrOxhwjIuvcA67KV2R5Jz6kr4abQsz"
            crossOrigin="anonymous"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="../static/css/custom.css"
          />
          {/* <script
            defer
            src="https://use.fontawesome.com/releases/v5.0.3/js/all.js"
          /> */}
          <script src="https://cdn.polyfill.io/v2/polyfill.min.js" />
        </Head>
        {this.props.children}
      </Container>
    );
  }
}
