import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

export default class PA2Footer extends React.Component {
  render() {
    return (
      <Container
        fluid
        style={{ backgroundColor: '#1D1D1D', padding: '30px 0px' }}
      >
        <Row
          className="justify-content-center"
          style={{ padding: '0 10px 0 10px', textAlign: 'center' }}
        >
          <Col xs="12">
            <img
              src="/static/img/fischer_logo_M_pos_4c.png"
              style={{
                borderRadius: '10px 10px 10px 10px',
                padding: '10px',
                backgroundColor: '#fff',
                width: '100%',
                minWidth: '200px',
                maxWidth: '270px'
              }}
              alt=""
            />
          </Col>
        </Row>
        <Row
          className="justify-content-center"
          style={{ padding: '30px 10px 0 10px', textAlign: 'center' }}
        >
          <Col xs="12">
            <p style={{ color: '#fff' }}>
              Fischer Italia S.r.l.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}
