import axios from 'axios';
import { data } from './config';
// lista actions
export const addTK = (val) => dispatch => {
  return dispatch({ type: 'addTK', payload: val });
};

export const pointsLoaded = (val) => dispatch => {
  return dispatch({ type: 'pointsLoaded', payload: val });
};

export const qtaLoaded = (val) => dispatch => {
  return dispatch({ type: 'qtaLoaded', payload: val });
};

// export const thisProd = (val) => dispatch => {
//   return dispatch({ type: 'thisProd', payload: val });
// };

export const getCarrello = (val) => async dispatch => {
  const newCart = await axios.get(`${data.serverUrl}/api/users/cart/${val}`);
  return dispatch({ type: 'getCarrello', payload: newCart.data[0].carrello });
};

export const carrelloTotali = (puntiTot, valoreTot, ivaTot) => dispatch => {
  return dispatch({
    type: 'carrelloTotali',
    payload: {
      punti: puntiTot,
      valore: valoreTot,
      iva: ivaTot,
    }
   });
};

export const addQta = (id, cart) => dispatch => {
  let thisCart = {};
    for (let i = 0; i < cart.length; i++) {
      if (cart[i].idPiat === id) {
        thisCart = cart[i];
        return thisCart;
      }
    }

  const newQta = thisCart;
  return dispatch({ type: 'addQta', payload: id, idQta: newQta });
};

export const carrelloProdPattui = val => dispatch => dispatch({ type: 'carrelloProdPattui', payload: val });
export const carrelloValComm = val => dispatch => dispatch({ type: 'carrelloValComm', payload: val });
export const totalePuntiPattui = val => dispatch => dispatch({ type: 'totalePuntiPattui', payload: val });
// https://stackoverflow.com/questions/39813984/how-to-fetch-data-through-api-in-redux
